import React, { forwardRef } from 'react'
import { Card, IconButton, Dialog, Button, CardActionArea, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  meetingCard: {
    width: 325,
    height: 175,
    margin: 20,
    borderRadius: 10
  },
  selectedMeetingCard: {
    width: 'calc(325px - 4px)',
    height: 'calc(175px - 4px)',
    margin: 20,
    borderRadius: 10
  },
  meetingCardActionArea: {
    padding: 20,
    height: '100%'
  },
  button: {
    height: 50,
    width: 125,
    borderRadius: 30,
    margin: 30
  },
  instructions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontWeight: 300,
    margin: '75px 10px 10px 10px',
    textAlign: 'center',
    marginBottom: 5
  },
  error: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontWeight: 300,
    margin: '0px 10px 10px 10px',
    textAlign: 'center',
    marginBottom: 5,
    color: 'red'
  }
}))

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='left' ref={ref} {...props} timeout={500} />
})

const FullScreenDialog = props => {
  const classes = useStyles()
  // const meetingInfo = props.meetingInfo
  const availabilityError = props.availabilityError
  const handleMeetingSelect = props.handleMeetingSelect
  const meetingTypes = props.profileInfo.attributes?.meeting_types || []
  const small = props.small
  const primaryColor = props.profileInfo.attributes.primary_color || '#975ce6'
  const contrastColor = props.profileInfo.attributes.contrast_color || '#FFFFFF'
  const loading = props.loading
  const profileInfo = props.profileInfo

  const hasProfilePicture = Boolean(profileInfo.attributes.profile_picture_url)

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <div style={{ backgroundColor: primaryColor }}>
          <div style={{ margin: 30, marginLeft: 20, marginRight: 60, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: 475, marginRight: 50 }}>
              <IconButton onClick={props.onClose} style={{ marginRight: 20, color: contrastColor || 'white' }}>
                <ArrowBackIcon />
              </IconButton>
              {hasProfilePicture && (
                <img
                  src={profileInfo.attributes?.profile_picture_url}
                  style={{ borderRadius: 100, height: 90, border: '2px solid white', width: 90, marginRight: 20 }}
                />
              )}
              <div>
                <div style={{ fontSize: small ? 20 : 28, fontWeight: 500, color: contrastColor || 'white' }}>
                  {profileInfo.attributes.agent_name}
                </div>
                <div style={{ color: contrastColor || 'white', fontSize: small ? 14 : 20, fontWeight: 300 }}>
                  {profileInfo.attributes.company_attributes?.company_name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.instructions} style={{ fontSize: small ? 24 : 32 }}>
            {loading ? 'Getting the calendar ready...' : 'Please choose one of the meetings below:'}
          </div>
          {availabilityError && (
            <div className={classes.error} style={{ fontSize: small ? 18 : 24 }}>
              {availabilityError}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', height: 40 }}>
            {loading &&
              <CircularProgress
                size={40}
                style={{
                  left: 125,
                  opacity: '0.5',
                  color: primaryColor
                }}
              />}
          </div>
          <div className={classes.container}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '30px 50px 50px 50px' }}>
              {meetingTypes.map((meetingType, index) => (
                <Card
                  raised
                  key={index}
                  className={classes.meetingCard}
                >
                  <CardActionArea onClick={() => handleMeetingSelect(meetingType.id)} className={classes.meetingCardActionArea}>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'baseline', height: 60, overflow: 'hidden', marginBottom: 20 }}>
                        <div style={{ borderRadius: 50, height: 15, backgroundColor: primaryColor, width: 15, marginRight: 10 }} />
                        <div>
                          <div style={{ fontSize: 20 }}>
                            {meetingType.attributes.name}
                          </div>
                          <div style={{ fontSize: 12, fontWeight: 300, color: '#777777' }}>
                            {meetingType.attributes.length_in_minutes} minutes.
                          </div>
                        </div>
                      </div>
                      <div>
                        <Button variant='outlined' fullWidth>
                        Select this Meeting
                        </Button>
                      </div>
                    </div>
                  </CardActionArea>
                </Card>
              ))}
            </div>
          </div>
        </div>
        {/* <div style={{ position: 'fixed', bottom: 0, right: 0 }}>
          <Button
            variant='contained'
            size='large'
            onClick={props.handleNext}
            className={classes.button}
            disabled={!meetingInfo.id}
            endIcon={<ArrowForwardIcon>NEXT</ArrowForwardIcon>}
            style={meetingInfo.id ? { backgroundColor: primaryColor, color: contrastColor } : {}}
          >
          Next
          </Button>
        </div> */}
      </Dialog>
    </div>
  )
}

export default FullScreenDialog
