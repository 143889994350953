import React, { forwardRef } from 'react'
import { Dialog, Button, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import TodayIcon from '@material-ui/icons/Today'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  confirmedCard: {
    marginTop: 'auto',
    marginBottom: 'auto',
    backgroundColor: 'white',
    padding: '100px 150px',
    borderRadius: 10
  },
  phoneConfirmedCard: {
    marginTop: 'auto',
    marginBottom: 'auto',
    backgroundColor: 'white',
    padding: '50px 30px',
    borderRadius: 10,
    marginLeft: 25,
    marginRight: 25
  },
  inlineItems: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} timeout={500} />
})

const MeetingBookedDialog = props => {
  const classes = useStyles()
  const confirmedDetails = props.confirmedDetails
  const small = props.small

  const profileInfo = props.profileInfo
  const primaryColor = profileInfo.attributes?.primary_color

  const FormattedDates = () => {
    const momentStartDate = moment(confirmedDetails.attributes.scheduled_start)
    const momentEndDate = moment(confirmedDetails.attributes.scheduled_end)

    const dateString = momentStartDate.format('h:mmA') + ' - ' + momentEndDate.format('h:mmA') + ', ' + momentStartDate.format('dddd, MMMM Do, YYYY')

    return (
      <div style={{ color: primaryColor, fontSize: 14 }}>
        {dateString}
      </div>
    )
  }

  if (!confirmedDetails || !profileInfo) {
    return <></>
  }

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <div style={{ backgroundColor: primaryColor, height: '100%', display: 'flex', justifyContent: 'center' }}>
          <div className={small ? classes.phoneConfirmedCard : classes.confirmedCard}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <DoneIcon style={{ transform: 'scale(1.5)' }} />
              <div style={{ fontSize: 28, marginLeft: 15 }}>
                Confirmed
              </div>
            </div>
            <div className={classes.inlineItems} style={{ justifyContent: 'center', color: '#545454', marginTop: 5, textAlign: 'center' }}>
              You are scheduled with {profileInfo.attributes.agent_name}
            </div>
            <div style={{ marginTop: 35 }}>
              <div className={classes.inlineItems}>
                <div style={{ height: 14, width: 14, borderRadius: 40, backgroundColor: primaryColor, marginLeft: 3 }} />
                <div style={{ fontSize: 22, marginLeft: 5 }}>
                  {confirmedDetails.attributes.meeting_name}
                </div>
              </div>
              <div className={classes.inlineItems} style={{ marginTop: 5, textAlign: 'center' }}>
                <TodayIcon size='sm' style={{ fill: primaryColor }} />
                <FormattedDates />
              </div>
              <div style={{ color: '#545454', marginTop: 30, fontSize: 12, textAlign: 'center' }}>
                A calendar invitation has been sent to your email address
              </div>
              <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant='outlined'
                  style={{ border: `1px solid ${primaryColor}`, color: primaryColor }}
                  onClick={props.onClose}
                >
                  Go Back to Profile

                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default MeetingBookedDialog
