import { addDays, addMinutes, formatRFC3339 } from 'date-fns'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

const headers = {
  'Content-Type': 'application/vnd.api+json'
}

const getDaysDict = (slots, tz) => {
  const timezone = tz || 'America/Denver'
  const dict = {}
  slots.map(s => {
    const start = new Date(s.start)
    const momentStart = moment(start)
    const tzParcedStart = momentTimezone.tz(momentStart, timezone)
    const day = tzParcedStart.format('YYYY/MM/DD')

    if (dict[day]) {
      dict[day].push(start)
    } else {
      dict[day] = [start]
    }
  })

  return dict
}

const _getSlots = (userId, tenantId, appURL, meetingTypeId) => {

  const body = {
    data: {
      attributes: {
        calendar_user_id: userId,
        meeting_type_id: meetingTypeId,
        tenant_id: tenantId
      },
    }
  }

  return fetch(`https://${appURL}/api/integrations/cronofy/get_user_calendar_slots`, {
    headers: headers,
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

const createCalendarEvent = ({ start, end, description, userId, tenantId, email, attendeeName, phone, appURL, reminderLeadTime, notes, meetingName, signatureText, meetingTypeId }) => {
  return fetch(`https://${appURL}/api/integrations/cronofy/_post_event_from_agent_profile_page`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: {
        type: 'calendar_drop_event',
        attributes: {
          scheduled_start: start,
          scheduled_end: end,
          summary: '',
          description: description,
          url: '',
          reminder_lead_time: reminderLeadTime || 0,
          conversation_event_id: '',
          conferencing_type: 'cronofy',
          notes: notes,
          meeting_name: meetingName,
          signature_text: signatureText,
          meeting_type_id: meetingTypeId
        },
        relationships: {
          for_user: { data: { type: 'users', id: userId } },
          tenant: { data: { type: 'tenants', id: tenantId } },
          attendee: { data: { email: email, name: attendeeName, phone: phone } }
        }
      }
    })
  })
    .then(response => response.json())
}

const getCalendarToken = (userId, tenantId, appURL) => {
  const body = {
    data: {
      relationships: {
        for_user: {
          data: { id: userId }
        },
        tenant: {
          data: { id: tenantId }
        }
      }
    }
  }

  return fetch(`https://${appURL}/api/integrations/cronofy/calendar_drop_token`, {
    headers: headers,
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then(response => response.json())
}

const getSlots = ({ elementToken, memberSub, duration }) => {
  const url = 'https://api.cronofy.com/v1/availability?et=' + elementToken
  const startDate = new Date()

  // if (drop.data.lead_time) {
  //   start_date = addMinutes(start_date, drop.data.lead_time)
  // }

  const endDate = addDays(startDate, 14)
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Cronofy-Element': 'v1.9.1, SlotPicker'
  }
  const body = {
    participants: [
      {
        members: [
          {
            sub: memberSub,
            managed_availability: true
          }
        ],
        required: 'all'
      }
    ],
    required_duration: { minutes: duration },
    response_format: 'overlapping_slots',
    available_periods: [
      { start: formatRFC3339(startDate), end: formatRFC3339(endDate) }
    ]
  }
  return fetch(
    url,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers
    }
  ).then(response => response.json())
}

export { getCalendarToken, getSlots, _getSlots, getDaysDict, createCalendarEvent }
