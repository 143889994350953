// import another component
import 'core-js'
import React, { useEffect, useState, useRef } from 'react'
import { Card, IconButton, Dialog, Button, DialogTitle, Divider, Tooltip, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import WorkIcon from '@material-ui/icons/Work'
import EventIcon from '@material-ui/icons/Event'
import { Integrations } from '@sentry/tracing'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import EmailIcon from '@material-ui/icons/Email'
import AdjustIcon from '@material-ui/icons/Adjust'
import FullScreenDialog from './components/FullScreenDialog'
import CalendarDialog from './components/CalendarDialog'
import MeetingBookedDialog from './components/MeetingBookedDialog'
import './main.css'
import PoweredBySignals from 'url:./img/PoweredBySignals.svg'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import LanguageIcon from '@material-ui/icons/Language'
import { _getSlots, getDaysDict } from './api/calendars'

const cfUsers = [
  'billy-bateman',
  'jeffrey-mcclellan',
  'adam-wall',
  'jordan-crandell',
  'diego-vanegas',
  'damarius-koy-henderson',
  'alex-durrant',
  'jeremiah-johnson',
  'ethan-ashton',
  'taylor-fletcher-1',
  'jenny-young',
  'mike-nash',
  'evan-mckague',
  'david-elkington',
  'mark-bryson',
  'tanner-sundwall',
  'colton-yourell',
  'jake-johnson',
  'pete-ketchum',
  'ronald-lopez',
  'colin-dunn'
]
const appURLs = {
  dev: 'local.chatfunnels.dev:12345',
  beta: 'beta.chatfunnels.com',
  prod: 'app.chatfunnels.com'
}

const url = new URL(window.location.href)
const subdomain = url.hostname.split('.')[0]

let env = 'prod'
let sampleRate = 0.1
let referenceCode = url.pathname.replace(/^\//, '')
if (url.hostname === 'local.chatfunnels.dev') {
  env = 'dev'
  sampleRate = 1.0
} else if (url.pathname.startsWith('/0/') || cfUsers.includes(referenceCode)) {
  // The hard-coded list of cfUsers is for backwards compatilibity. Moving forward, the
  // generated beta URLs will include a /0/ prefix, but just in case URLs without that
  // prefix have been distributed, this will allow the old urls to work
  env = 'beta'
  if (url.pathname.startsWith('/0/')) {
    referenceCode = url.pathname.substr(3)
  }
}

const appURL = appURLs[env]
Sentry.init({
  dsn: 'https://5416834f1ce747eb91aa3cb9647c92f3@o265885.ingest.sentry.io/5465644',
  environment: env,
  integrations: [
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: sampleRate
})

const useStyles = makeStyles(theme => ({
  cardHeader: {
    height: 100,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: '1px solid #d9d9d9'
  },
  profileCard: {
    borderRadius: 10,
    margin: 40
  },
  aboutCard: {
    borderRadius: 10,
    margin: 40,
    paddingTop: 30,
    paddingLeft: 40
  },
  companyCard: {
    borderRadius: 10,
    margin: 40,
    paddingTop: 30,
    paddingLeft: 40,
    paddingBottom: 30,
    position: 'relative'
  },
  profileCardBody: {
    height: 200
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    maxWidth: 800,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  profilePicture: {
    height: 100,
    width: 100,
    position: 'absolute',
    top: 50,
    borderRadius: 50,
    border: '2px solid white',
    left: 40
  },
  subtitle: {
    fontWeight: 500,
    color: '#a0a0a0'
  },
  agentName: {
    fontSize: 32
  },
  button: {
    marginRight: 5
  },
  smallScreenButton: {
    width: '100%',
    marginBottom: 10
  },
  companyPicture: {
    height: 40,
    width: 40,
    paddingTop: 10,
    display: 'block',
    marginLeft: 'auto',
    objectFit: 'contain',
    marginRight: 'auto'
  },
  companyGrid: {
    display: 'grid',
    gridTemplateColumns: '150px auto',
    gridRowGap: '30px'
  },
  companyImageContainer: {
    width: 60,
    marginRight: 20,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 50,
    overflow: 'hidden'
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -15
  }
}))

const icons = [
  { type: 'linkedin', iconComponent: LinkedInIcon, tooltip: 'LinkedIn' },
  { type: 'twitter', iconComponent: TwitterIcon, tooltip: 'Twitter' },
  { type: 'facebook', iconComponent: FacebookIcon, tooltip: 'Facebook' },
  { type: 'instagram', iconComponent: InstagramIcon, tooltip: 'Instagram' },
  { type: 'website', iconComponent: LanguageIcon, tooltip: 'Website' }
]

const SocialMediaIcon = props => {
  const profileInfo = props.profileInfo
  const socialMediaLinks = profileInfo.attributes?.social_media_links || {}

  if (Object.keys(socialMediaLinks).length && socialMediaLinks[props.type]) {
    return (
      <Tooltip title={props.tooltip} placement='bottom'>
        <IconButton onClick={() => window.open(socialMediaLinks[props.type], '_blank')} style={{ color: profileInfo.attributes?.primary_color }}>
          <props.component />
        </IconButton>
      </Tooltip>
    )
  } else {
    return <></>
  }
}

const SimpleDialog = props => {
  const classes = useStyles()
  const { onClose, open, profileInfo } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth='xs' open={open}>
      <DialogTitle disableTypography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 5, paddingLeft: 20 }}>
        <div style={{ fontSize: 18 }}>
          {profileInfo.attributes.agent_name}
        </div>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <div style={{ margin: 20, marginBottom: 30, marginLeft: 30 }}>
        <div style={{ display: 'flex' }}>
          <PhoneInTalkIcon style={{ marginRight: 15 }} />
          <div>
            <div>
              PHONE
            </div>
            <div>
              {profileInfo?.attributes?.phone}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <EmailIcon style={{ marginRight: 15 }} />
          <div>
            <div>
              EMAIL
            </div>
            <div>
              <a href={`mailto:${profileInfo.attributes.email}`}>{profileInfo.attributes.email}</a>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const IconText = props => {
  const leftIcon = props.icon
  let text = props.text
  if (props.handleAction) {
    text = <a href='javascript:void(0)' onClick={props.handleAction}>{props.text}</a>
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 20 }}>
      {leftIcon}
      <div style={{ marginLeft: 10, fontSize: 16 }}>
        {text}
      </div>
    </div>
  )
}

const App = props => {
  const classes = useStyles()
  const [profileInfo, setProfileInfo] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [calendarModalOpen, setCalendarModalOpen] = useState(false)
  const [meetingSelectorOpen, setMeetingSelectorOpen] = useState(false)
  const [meetingBookedModalOpen, setMeetingBookedModalOpen] = useState(false)
  const [confirmedDetails, setConfirmedDetails] = useState()
  const [meetingInfo, setMeetingInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [availabilityError, setAvailabilityError] = useState()
  const chatClientRef = useRef()
  const medium = useMediaQuery('(max-width: 1440px)')
  const small = useMediaQuery('(max-width: 550px)')

  // Calendar State
  const [timezone, setTimeZone] = useState()
  const [availableDates, setAvailableDates] = useState([])
  const [slots, setSlots] = useState()
  const [date, changeDate] = useState(new Date())

  const setSelectedCard = (key) => {
    const selectedCard = profileInfo.attributes.meeting_types.filter(mt => mt.id === key)[0]
    if (selectedCard) {
      setMeetingInfo(selectedCard)
      return selectedCard
    } else {
      return false
    }
  }

  const handleMeetingSelect = (meetingId) => {
    const card = setSelectedCard(meetingId)
    if (card) {
      setLoading(true)
      getTimeSlots(meetingId)
    }
  }

  const handleBookMeetingButtonClick = () => {
    if (profileInfo && profileInfo?.attributes?.meeting_types) {
      if (profileInfo.attributes.meeting_types.length === 1) {
        const meetingId = profileInfo.attributes.meeting_types[0].id
        if (meetingId) {
          handleMeetingSelect(meetingId)
        }
      } else {
        setMeetingSelectorOpen(true)
      }
    }
  }

  const getTimeSlots = (meetingTypeId) => {
    if (hasCalendar) {
      setAvailabilityError()
      _getSlots(
        profileInfo.attributes.user_id,
        profileInfo.attributes.tenant_id,
        appURL,
        meetingTypeId
      )
        .then(response => {
          const dict = getDaysDict(response.available_slots, timezone)
          const dates = Object.keys(dict)
          if (dates?.length) {
            changeDate(dates[0])
            setAvailableDates(dates)
            setSlots(dict)
            setCalendarModalOpen(true)
            setLoading(false)
          } else {
            setAvailabilityError('It seems there are no times available for this meeting type.')
            setLoading(false)
          }
        })
    }
  }

  useEffect(() => {
    const lookupUrl = `https://${appURL}/api/agent_profile_pages/quick_lookup`
    fetch(lookupUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Pragma: 'no-cache'
      },
      body: JSON.stringify({
        subdomain: subdomain,
        reference_code: referenceCode
      })
    })
      .then((response) => response.json())
      .then(response => {
        setProfileInfo(response.data)
        document.title = response.data.attributes.agent_name + "'s Signals Profile"
      })
  }, [])

  useEffect(() => {
    if (profileInfo) {
      const packetCode = profileInfo.attributes?.packet_code
      const loadPath = profileInfo.attributes?.load_path
      const servicePath = profileInfo.attributes?.service_path

      if (packetCode) {
        window.cf_chat_loader = () => {
          return {
            user_id: profileInfo.attributes?.user_id,
            appLoaded: (cf, e) => {
              setTimeout(() => {
                cf._internal.createChatWithUserId(profileInfo.attributes.user_id)
              }, 500)
            },
            disabled: true,
            parentElement: chatClientRef.current,
            packet_code: packetCode,
            app_url: `https://${loadPath}/chat-client/`,
            service_address: servicePath
          }
        }

        const el = document.createElement('script')
        el.async = true
        el.src = `https://${loadPath}/chat-client/chat-loader.js`
        if (window.document.body) {
          window.document.body.appendChild(el)
        }
      }
    }
  }, [profileInfo])

  const parseURL = url => {
    const hashes = url.slice(url.indexOf('?') + 1).split('&')
    const params = {}
    hashes.map(hash => {
      const [key, val] = hash.split('=')
      params[key] = decodeURIComponent(val)
      return 1
    })

    return params
  }

  useEffect(() => {
    const queryParams = parseURL(window.location.search)
    if (queryParams.m_id && profileInfo) {
      handleMeetingSelect(Number.parseInt(queryParams.m_id))
    }
  }, [profileInfo])

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    setTimeZone(tz)
  }, [])

  if (!profileInfo) {
    return <></>
  }

  const hasCalendar = profileInfo.attributes.has_calendar
  const hasProfilePicture = Boolean(profileInfo.attributes.profile_picture_url)

  return (
    <>
      <div className={classes.root} ref={chatClientRef}>
        <div className={classes.container}>
          <Card raised className={classes.profileCard} style={{ position: 'relative' }}>
            <div className={classes.cardHeader} style={{ backgroundColor: profileInfo.attributes.primary_color }}>
              {profileInfo.attributes.company_attributes?.company_logo_url &&
                <div className={classes.companyImageContainer}>
                  <img
                    src={profileInfo.attributes.company_attributes?.company_logo_url}
                    className={classes.companyPicture}
                  />
                </div>}
            </div>
            <div style={{ display: 'flex', padding: '10px 40px 40px 30px', flexWrap: 'wrap' }}>
              {hasProfilePicture && (
                <div style={{ flexBasis: 120, flexGrow: 1, display: 'flex', justifyContent: 'start' }}>
                  {hasProfilePicture && (
                    <img className={classes.profilePicture} src={profileInfo.attributes.profile_picture_url} />
                  )}
                  <div style={{ marginTop: 65, marginLeft: 10 }}>
                    <div>
                      {icons.map((icon, index) => (
                        <SocialMediaIcon
                          component={icon.iconComponent}
                          tooltip={icon.tooltip}
                          type={icon.type} key={index}
                          profileInfo={profileInfo}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div style={{ flexGrow: 10, marginLeft: 25 }}>
                <div>
                  <div className={classes.agentName}>
                    {profileInfo.attributes.agent_name}
                  </div>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AdjustIcon style={{ height: 15, width: 15, borderRadius: 20, marginRight: 5, color: profileInfo.attributes.agent_status === 'available' ? 'rgba(0, 128, 0, 0.7)' : '#636363' }} />
                      {profileInfo.attributes.agent_status === 'available' ? 'Available' : 'Unavailable'}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 25 }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {profileInfo.attributes.agent_profile_settings?.display_chat_with_me && (
                      <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        style={{
                          backgroundColor: profileInfo.attributes.primary_color,
                          color: profileInfo.attributes.contrast_color,
                          display: !profileInfo.attributes.agent_profile_settings?.display_chat_with_me ? 'none' : null
                        }}
                        className={small ? classes.smallScreenButton : classes.button}
                        startIcon={<ChatBubbleIcon />}
                        onClick={() => {
                          window.ChatFunnels._internal.hard_enable()
                        }}
                      >
                        Chat with me
                      </Button>
                    )}
                    {profileInfo.attributes.agent_profile_settings?.display_calendar && (
                      <Button
                        size='small'
                        variant='contained'
                        disabled={!hasCalendar}
                        style={
                          hasCalendar ? {
                            border: `1px solid ${profileInfo.attributes.primary_color}`,
                            backgroundColor: profileInfo.attributes.primary_color,
                            color: profileInfo.attributes.contrast_color
                          }
                            : {}
                        }
                        color='secondary'
                        onClick={handleBookMeetingButtonClick}
                        className={small ? classes.smallScreenButton : classes.button}
                        startIcon={<EventIcon />}
                      >
                        Schedule a Meeting
                        {loading &&
                          <CircularProgress
                            size={20} style={{
                              color: profileInfo.attributes.primary_color
                            }}
                            className={classes.circularProgress}
                          />}
                      </Button>
                    )}
                  </div>
                  {profileInfo.attributes.company_attributes?.company_name && (
                    <IconText icon={<WorkIcon fontSize='small' />} text={profileInfo.attributes.company_attributes?.company_name} />
                  )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconText icon={<PermContactCalendarIcon fontSize='small' />} text='Contact Info' handleAction={() => setModalOpen(true)} />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {/* <Card raised className={classes.aboutCard}>
            <div style={{ fontWeight: 500 }}>
              ABOUT
            </div>
            <Divider style={{ marginRight: 40, marginTop: 5, marginBottom: 5 }} />
            <div style={{ paddingRight: 40 }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => setModalOpen(true)}>
                <InfoOutlinedIcon style={{ fill: profileInfo.attributes.theme_color }} />
              </IconButton>
            </div>
          </Card> */}

          {Object.keys(profileInfo.attributes.company_attributes || {}).length > 0 &&
            (
              <Card raised className={classes.companyCard}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 500 }}>
                    COMPANY OVERVIEW
                  </div>
                </div>
                <Divider style={{ marginRight: 40, marginTop: 5, marginBottom: 15 }} />
                {profileInfo.attributes.company_attributes.bio && (
                  <>
                    <div style={{ paddingRight: 40, marginBottom: 15, fontWeight: 300 }}>
                      {profileInfo.attributes.company_attributes.bio}
                    </div>
                    <Divider style={{ marginRight: 40, marginTop: 5 }} />
                  </>
                )}
                <br />
                <div className={small ? classes.smallCompanyGrid : classes.companyGrid}>
                  {profileInfo.attributes.company_attributes.website && (
                    <>
                      <div className={classes.subtitle}>WEBSITE</div>
                      <div style={{ marginBottom: small && 20 }}>
                        <a
                          target='_blank' rel='noopener noreferrer'
                          href={`https://${profileInfo.attributes.company_attributes.website}`}
                        >{profileInfo.attributes.company_attributes.website}
                        </a>
                      </div>
                    </>
                  )}
                  {profileInfo.attributes.company_attributes.industry && (
                    <>
                      <div className={classes.subtitle}>INDUSTRY</div>
                      <div style={{ marginBottom: small && 20 }}>{profileInfo.attributes.company_attributes.industry}</div>
                    </>
                  )}
                  {profileInfo.attributes.company_attributes.company_size && (
                    <>
                      <div className={classes.subtitle}>COMPANY SIZE</div>
                      <div style={{ marginBottom: small && 20 }}>{profileInfo.attributes.company_attributes.company_size}</div>
                    </>
                  )}
                  {profileInfo.attributes.company_attributes.founded && (
                    <>
                      <div className={classes.subtitle}>FOUNDED</div>
                      <div style={{ marginBottom: small && 20 }}>{profileInfo.attributes.company_attributes.founded}</div>
                    </>
                  )}
                  {profileInfo.attributes.company_attributes.headquarters && (
                    <>
                      <div className={classes.subtitle}>HEADQUARTERS</div>
                      <div style={{ marginBottom: small && 20 }}>{profileInfo.attributes.company_attributes.headquarters}</div>
                    </>
                  )}
                </div>
                <br />
              </Card>
            )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={PoweredBySignals} height={50} />
          </div>
        </div>
        <SimpleDialog
          open={modalOpen}
          profileInfo={profileInfo}
          onClose={() => setModalOpen(false)}
        />
        <FullScreenDialog
          open={meetingSelectorOpen}
          onClose={() => setMeetingSelectorOpen(false)}
          handleMeetingSelect={handleMeetingSelect}
          availabilityError={availabilityError}
          profileInfo={profileInfo}
          meetingInfo={meetingInfo}
          small={small}
          loading={loading}
        />
        <CalendarDialog
          open={calendarModalOpen}
          timezone={timezone}
          small={small}
          medium={medium}
          handleSuccess={(response) => {
            setMeetingSelectorOpen(false)
            setCalendarModalOpen(false)
            setConfirmedDetails(response.data)
            setMeetingBookedModalOpen(true)
          }}
          // hasCalendar={hasCalendar}
          slots={slots}
          date={date}
          changeDate={changeDate}
          availableDates={availableDates}
          profileInfo={profileInfo}
          meetingInfo={meetingInfo}

          onClose={() => {
            setMeetingSelectorOpen(false)
            setCalendarModalOpen(false)
          }}
          onBack={() => {
            setCalendarModalOpen(false)
          }}
        />
        <MeetingBookedDialog
          open={meetingBookedModalOpen}
          onClose={() => {
            setMeetingBookedModalOpen(false)
          }}
          confirmedDetails={confirmedDetails}
          profileInfo={profileInfo}
          small={small}
        />
      </div>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
